
export const HROUTES: any[] = [
  {
    path: '/app/dashboard', title: 'Dashboard', icon: 'ft-home', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: []
  },
  {
    path: '/app/workflow', title: 'Workflow', icon: 'ft-codepen', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: []
  },
  {
    path: '/app/crons', title: 'Crons', icon: 'ft-clock', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: []
  },
  {
    path: '', title: 'Trigger', icon: 'ft-cpu', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
      { path: '/app/trigger-workflow', title: 'Workflow', icon: 'ft-arrow-right ft-codepen', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/app/trigger-cron', title: 'Cron', icon: 'ft-arrow-right ft-clock', class: 'dropdown-item', isExternalLink: false, submenu: [] },
    ]
  },
];
