import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { EnvironmentService, IEnv } from './environment.service';

export interface IMonitoringException {
  id: number;
  aws_task_id: string;
  exception: string;
  message: string;
  stacktrace: string;
  raised_at: string;

}

export interface IMonitoring {
  id: number;
  aws_task_id: string;
  aws_log_group: string;
  aws_log_stream: string;
  started_at: string;
  stopped_at: string;
  s3_key: string;
  status: string;
  lines_validated: number;
  lines_rejected: number;
  lines_total: number;
  lines_created: number;
  lines_updated: number;
  lines_processed: number;
  acteur_releve: string;
  type: string;
  provider: string;
  s3_folder_date: string;
  eta: number;
  progression: number;
  duration: string | number;
  chunk_size: number;
  exceptions: IMonitoringException[]
}

export interface ICronStep {
  id: number;
  aws_task_id: string;
  started_at: string;
  stopped_at: string;
  status: string;
  position: number;
  duration: number;
}

export interface ICron {
  id: number;
  aws_task_id: string;
  aws_log_group: string;
  aws_log_stream: string;
  started_at: string;
  stopped_at: string;
  status: string;
  step_count: string;
  duration: number | string;
  steps: ICronStep[]
  exceptions: IMonitoringException[]
}

export interface PaginatedResponse<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService,
  ) {
    this.envService.environment$.subscribe((env: IEnv) => {
      this.apiUrl = env.apiUrl;
    })
  }

  getWorkflowPage(page: number = 1, page_size: number = 30, statusFilter: string = 'ALL', filterStringValue = ''): Observable<PaginatedResponse<IMonitoring>> {
    return this.http.get<PaginatedResponse<IMonitoring>>(
      `${this.apiUrl}/workflow/`,
      {
        params: new HttpParams().set('page', page.toString()).set('page_size', page_size.toString()).set('status', statusFilter).set('q', filterStringValue),
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('credentials.id_token')
        },
      }
    );
  }

  getCronPage(page: number = 1, page_size: number = 30): Observable<PaginatedResponse<ICron>> {
    return this.http.get<PaginatedResponse<ICron>>(
      `${this.apiUrl}/cron/`,
      {
        params: new HttpParams().set('page', page.toString()).set('page_size', page_size.toString()),
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('credentials.id_token')
        },
      }
    );
  }

  getWorkflowTask(aws_task_id: string): Observable<IMonitoring> {
    return this.http.get<IMonitoring>(
      `${this.apiUrl}/workflow/${aws_task_id}/`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('credentials.id_token')
        },
      }
    );
  }

  getCronTask(aws_task_id: string): Observable<ICron> {
    return this.http.get<ICron>(
      `${this.apiUrl}/cron/${aws_task_id}/`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('credentials.id_token')
        },
      }
    );
  }

  getDashboard(): Observable<any> {
    return this.http.get<ICron>(
      `${this.apiUrl}/dashboard/`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('credentials.id_token')
        },
      }
    );
  }

  kill(aws_task_id, type) {
    return this.http.get<IMonitoring>(
      `${this.apiUrl}/${type}/${aws_task_id}/kill/`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('credentials.id_token')
        },
      }
    );
  }

  rerun(aws_task_id, type) {
    return of();
    // return this.http.get<IMonitoring>(
    //   `${this.apiUrl}/workflow/${aws_task_id}/kill/`,
    //   {
    //     headers: {
    //       Authorization: 'Bearer ' + localStorage.getItem('credentials.id_token')
    //     },
    //   }
    // );
  }

  logs(aws_task_id, type) {
    return this.http.get<IMonitoring>(
      `${this.apiUrl}/${type}/${aws_task_id}/logs/`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('credentials.id_token')
        },
      }
    );
  }

  rejects(aws_task_id) {
    return this.http.get<any>(
      `${this.apiUrl}/workflow/${aws_task_id}/rejects/`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('credentials.id_token')
        },
      }
    );
  }

  createWorkflowFile(content: string, type: string): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/workflow/create-workflow-file/`,
      {
        content,
        type
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('credentials.id_token')
        },
      }
    );
  }

  executeWorkflow(key: string): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/workflow/task/`,
      {
        key,
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('credentials.id_token')
        },
      }
    );
  }
}
